import { Injectable } from "@angular/core";
import { MEASURES } from "src/app/core/classes/Measure";

@Injectable({
  providedIn: "root",
})
export class JsonSecondaryLanguageService {
  constructor() {}

  async getJSON(formValue, value_SECONDARY) {
    var json_secondary = {
      pages: formValue.pages_secondary,
      language: value_SECONDARY.language,
      graphics: {
        menuBottomLogoPath: formValue.secondaryLogo_09_filename_secondary,
        menuTopLogoPath: formValue.menuLogo_09_filename_secondary,
        headerLogoPath: formValue.headerLogo_10_filename_secondary,
      },
      legalNotices: {
        legalInformation: value_SECONDARY.legalNotices.legalInformation,
        privacyPolicy: value_SECONDARY.legalNotices.privacyPolicy,
      },
      menu: {
        menuLinks: formValue.links_menu_secondary,
        bottomText: formValue.internalUsage_09_secondary,
      },
      contentBlockingModal: {
        title: formValue.contentBlockingModalTitle_secondary,
        content: formValue.contentBlockingModalContent_secondary,
        accept: formValue.contentBlockingModalAccept_secondary,
        decline: formValue.contentBlockingModalDecline_secondary,
      },
      passwordModal: {
        title: formValue.passwordModalTitle_secondary,
        content: formValue.passwordModalContent_secondary,
      },
      map: {
        startDateCampaign: formValue.campaignDate_04_primary[0].getTime(),
        endDateCampaign: formValue.campaignDate_04_primary[1].getTime(),
        dataPath: formValue.mapData_04_filename_primary,
        activated: formValue.activateDashboardCheck_04_primary ? true : false,
        title: formValue.subtitle_04_secondary,
        legend1: formValue.label1_04_secondary,
        legend2: formValue.label2_04_secondary,
        informationAboutData: formValue.information_data_content_04_secondary,
      },
    };
    return json_secondary;
  }

  async getJSON_old(disease, formValue, value_SECONDARY) {
    var OPTIONS_DASHBOARD_ACTIVATED =
      formValue.activateDashboardCheck_04_primary ? true : false;

    var json_secondary = {
      LAST_MODIFICATION: Date.now(),
      LANGUAGE: {
        ENGLISH: value_SECONDARY[1].LANGUAGE.ENGLISH,
        FRENCH: value_SECONDARY[1].LANGUAGE.FRENCH,
        ITALIAN: value_SECONDARY[1].LANGUAGE.ITALIAN,
        LANGUAGE: value_SECONDARY[1].LANGUAGE.LANGUAGE,
        LANGUAGES: value_SECONDARY[1].LANGUAGE.LANGUAGES,
      },
      COOKIE_POLICY: {
        COOKIE_POLICY_HEADER:
          value_SECONDARY[1].COOKIE_POLICY.COOKIE_POLICY_HEADER,
        COOKIE_POLICY_TEXT: value_SECONDARY[1].COOKIE_POLICY.COOKIE_POLICY_TEXT,
      },
      GENERAL: {
        TO_HOME_PAGE: value_SECONDARY[1].GENERAL.TO_HOME_PAGE,
        SUCCESS: value_SECONDARY[1].GENERAL.SUCCESS,
        ERROR: value_SECONDARY[1].GENERAL.ERROR,
        SEND_ANSWER: value_SECONDARY[1].GENERAL.SEND_ANSWER,
      },
      MENU: {
        COOKIE_POLICY: value_SECONDARY[1].MENU.COOKIE_POLICY,
        INTERNAL_USE: formValue.internalUsage_09_secondary,
        LEGAL_INFORMATION: value_SECONDARY[1].MENU.LEGAL_INFORMATION,
        HEADER_COLOR: formValue.headerColor_09_secondary,
      },
      MOBILE_ONLY: {
        ROTATE_DEVICE: value_SECONDARY[1].MOBILE_ONLY.ROTATE_DEVICE,
      },
      LINK: {
        LOGO_HEADER: formValue.headerLogo_10_filename_secondary,
        LOGO_MENU: formValue.menuLogo_09_filename_secondary,
        LOGO_SANOFI: formValue.secondaryLogo_09_filename_secondary,
      },
      COVID: {
        COOKIE_INFORMATION: {
          COOKIE_INFO_CONTENT:
            value_SECONDARY[1].COVID.COOKIE_INFORMATION.COOKIE_INFO_CONTENT,
        },
        LEGALE_INFORMATION: {
          LEGALE_INFO_CONTENT:
            value_SECONDARY[1].COVID.LEGALE_INFORMATION.LEGALE_INFO_CONTENT,
        },
        DASHBOARD: {
          OPTIONS: {
            ACTIVATED:
              disease === "COVID"
                ? OPTIONS_DASHBOARD_ACTIVATED
                : value_SECONDARY[1].COVID.DASHBOARD.OPTIONS.ACTIVATED,
          },
          REGION:
            disease === "COVID"
              ? formValue.regionMap_04_primary
              : value_SECONDARY[1].COVID.DASHBOARD.REGION,
          FILE_NAME:
            disease === "COVID"
              ? formValue.mapData_04_filename_primary
              : value_SECONDARY[1].COVID.DASHBOARD.FILE_NAME,
          CAMPAIGN_DATE_START:
            disease === "COVID"
              ? formValue.campaignDate_04_primary[0].getTime()
              : value_SECONDARY[1].COVID.DASHBOARD.CAMPAIGN_DATE_START,
          CAMPAIGN_DATE_END:
            disease === "COVID"
              ? formValue.campaignDate_04_primary[1].getTime()
              : value_SECONDARY[1].COVID.DASHBOARD.CAMPAIGN_DATE_END,
          SUBTITLE:
            disease === "COVID"
              ? formValue.subtitle_04_secondary
              : value_SECONDARY[1].COVID.DASHBOARD.SUBTITLE,
          LABEL1:
            disease === "COVID"
              ? formValue.label1_04_secondary
              : value_SECONDARY[1].COVID.DASHBOARD.LABEL1,
          LABEL2:
            disease === "COVID"
              ? formValue.label2_04_secondary
              : value_SECONDARY[1].COVID.DASHBOARD.LABEL2,
          INFORMATION_ABOUT_DATA:
            disease === "COVID"
              ? formValue.information_data_content_04_secondary
              : value_SECONDARY[1].COVID.DASHBOARD.INFORMATION_ABOUT_DATA,
        },
        MENU: {
          MENU_LINKS:
            disease === "COVID"
              ? formValue.links_menu_secondary
              : value_SECONDARY[1].COVID.MENU.MENU_LINKS,
        },
        PAGES:
          disease === "COVID"
            ? formValue.pages_secondary
            : value_SECONDARY[1].COVID.PAGES,
      },
      FLU: {
        COOKIE_INFORMATION: {
          COOKIE_INFO_CONTENT:
            value_SECONDARY[1].FLU.COOKIE_INFORMATION.COOKIE_INFO_CONTENT,
        },
        LEGALE_INFORMATION: {
          LEGALE_INFO_CONTENT:
            value_SECONDARY[1].FLU.LEGALE_INFORMATION.LEGALE_INFO_CONTENT,
        },
        DASHBOARD: {
          OPTIONS: {
            ACTIVATED:
              disease === "FLU"
                ? OPTIONS_DASHBOARD_ACTIVATED
                : value_SECONDARY[1].FLU.DASHBOARD.OPTIONS.ACTIVATED,
          },
          REGION:
            disease === "FLU"
              ? formValue.regionMap_04_primary
              : value_SECONDARY[1].FLU.DASHBOARD.REGION,
          FILE_NAME:
            disease === "FLU"
              ? formValue.mapData_04_filename_primary
              : value_SECONDARY[1].FLU.DASHBOARD.FILE_NAME,
          CAMPAIGN_DATE_START:
            disease === "FLU"
              ? formValue.campaignDate_04_primary[0].getTime()
              : value_SECONDARY[1].FLU.DASHBOARD.CAMPAIGN_DATE_START,
          CAMPAIGN_DATE_END:
            disease === "FLU"
              ? formValue.campaignDate_04_primary[1].getTime()
              : value_SECONDARY[1].FLU.DASHBOARD.CAMPAIGN_DATE_END,
          SUBTITLE:
            disease === "FLU"
              ? formValue.subtitle_04_secondary
              : value_SECONDARY[1].FLU.DASHBOARD.SUBTITLE,
          LABEL1:
            disease === "FLU"
              ? formValue.label1_04_secondary
              : value_SECONDARY[1].FLU.DASHBOARD.LABEL1,
          LABEL2:
            disease === "FLU"
              ? formValue.label2_04_secondary
              : value_SECONDARY[1].FLU.DASHBOARD.LABEL2,
          INFORMATION_ABOUT_DATA:
            disease === "FLU"
              ? formValue.information_data_content_04_secondary
              : value_SECONDARY[1].FLU.DASHBOARD.INFORMATION_ABOUT_DATA,
        },
        MENU: {
          MENU_LINKS:
            disease === "FLU"
              ? formValue.links_menu_secondary
              : value_SECONDARY[1].FLU.MENU.MENU_LINKS,
        },
        PAGES:
          disease === "FLU"
            ? formValue.pages_secondary
            : value_SECONDARY[1].FLU.PAGES,
      },
    };
    return json_secondary;
  }
}
